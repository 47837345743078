import "../scss/style.scss";

if (process.env.NODE_ENV !== 'production') {
    console.warn('Looks like we are in development mode!');
} 

if (typeof Diezel === 'undefined') {
  var Diezel = {};
}

!function () {

    var readmores = document.querySelectorAll('.btn-read-more');

    readmores.forEach(readmore => {
      readmore.onclick = function(e){
        e.preventDefault();
        var $hidden = e.target.parentElement.querySelector('.hidden');
        console.log($hidden);
        $hidden.style.display = 'inline';
        e.target.style.display = 'none';
      }
    })

    var faqTriggers = document.querySelectorAll('.trigger');
    faqTriggers.forEach(faqTrigger => {
      faqTrigger.onclick = function(e){
        e.preventDefault();
        e.target.parentElement.classList.toggle('visible');
      }
    })

}.call(Diezel);